import { ContentByChatQuery } from '@/@generated/graphql';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CellEditing } from '@unique/component-library';

type CreateDueDiligencePayload = {
  chatContent: ContentByChatQuery['contentByChat'];
  sheetName: string;
  importStepContentId?: string;
  sheetId: string;
};

export interface DueDiligenceState {
  editingCell: CellEditing | null;
  socketConnected: boolean;
  dueDiligencePayload: CreateDueDiligencePayload | null;
  isGridReady: boolean;
}

const initialState: DueDiligenceState = {
  editingCell: null,
  socketConnected: false,
  dueDiligencePayload: null,
  isGridReady: false,
};

export const dueDiligenceSlice = createSlice({
  name: 'dueDiligence',
  initialState,
  reducers: {
    updateEditingCell: (state, action: PayloadAction<CellEditing>) => {
      state.editingCell = action.payload;
    },
    updateSocketConnected: (state, action: PayloadAction<boolean>) => {
      state.socketConnected = action.payload;
    },
    updateDueDiligencePayload: (state, action: PayloadAction<CreateDueDiligencePayload>) => {
      state.dueDiligencePayload = action.payload;
    },
    setGridReady: (state, action: PayloadAction<boolean>) => {
      state.isGridReady = action.payload;
    },
  },
});

export const { updateEditingCell, updateSocketConnected, updateDueDiligencePayload, setGridReady } =
  dueDiligenceSlice.actions;

export default dueDiligenceSlice.reducer;
